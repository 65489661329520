export interface Role {
  name: string;
  slug: string;
  hash: string;
  permissions: Permission[];
}

export interface Permission {
  hash: string;
  type: string;
  grant: string;
  roles: Role[];
}

export enum ROLE_PERMISSION_TYPES_AVAILABLE {
  "TYPE_USERS" = "TYPE_USERS",
  "TYPE_PRODUCTS" = "TYPE_PRODUCTS",
  "TYPE_USERS_BILLING_ADDRESSES" = "TYPE_USERS_BILLING_ADDRESSES",
  "TYPE_USERS_PAYMENT_METHODS" = "TYPE_USERS_PAYMENT_METHODS",
  "TYPE_ORDERS" = "TYPE_ORDERS",
  "TYPE_TICKETS" = "TYPE_TICKETS",
  "TYPE_CLAIMS" = "TYPE_CLAIMS",
  "TYPE_ADMINS" = "TYPE_ADMINS",
  "TYPE_ROLES" = "TYPE_ROLES",
  "TYPE_PLANS" = "TYPE_PLANS",
  "TYPE_JUDICIAL_PRECEDENT" = "TYPE_JUDICIAL_PRECEDENT",
  "TYPE_JUDICIAL_PRECEDENT_INSTANCE" = "TYPE_JUDICIAL_PRECEDENT_INSTANCE",
  "TYPE_JUDICIAL_PRECEDENT_PRODUCTS" = "TYPE_JUDICIAL_PRECEDENT_PRODUCTS",
  "TYPE_JUDICIAL_PRECEDENT_AFFECTED_TYPE" = "TYPE_JUDICIAL_PRECEDENT_AFFECTED_TYPE",
  "TYPE_JUDGES" = "TYPE_JUDGES",
  "TYPE_LAWYERS" = "TYPE_LAWYERS",
  "TYPE_BANKS" = "TYPE_BANKS",
  "TYPE_AUDIENCES" = "TYPE_AUDIENCES",
  "TYPE_COURTS" = "TYPE_COURTS",
  "TYPE_COURTS_TYPE" = "TYPE_COURTS_TYPE",
  "TYPE_FAQS" = "TYPE_FAQS",
  "TYPE_PUBLIC_FILES" = "TYPE_PUBLIC_FILES",
  "TYPE_PUBLIC_FILES_TYPE" = "TYPE_PUBLIC_FILES_TYPE",
  "TYPE_PUBLIC_FILES_PRODUCTS" = "TYPE_PUBLIC_FILES_PRODUCTS",
  "TYPE_NEWS" = "TYPE_NEWS",
  "TYPE_ANALYTICS" = "TYPE_ANALYTICS",
  "TYPE_ANALYTICS_PLANS" = "TYPE_ANALYTICS_PLANS",
  "TYPE_ANALYTICS_JUDICIAL_SENTENCES" = "TYPE_ANALYTICS_JUDICIAL_SENTENCES",
  "TYPE_ANALYTICS_FILES" = "TYPE_ANALYTICS_FILES",
  "TYPE_FINANCIAL_STRIPE" = "TYPE_FINANCIAL_STRIPE",
  "TYPE_HEADQUARTES" = "TYPE_HEADQUARTES",
  "TYPE_CONFIGS" = "TYPE_CONFIGS",
  "TYPE_CITIES" = "TYPE_CITIES",
  "TYPE_PROVINCES" = "TYPE_PROVINCES",
  "TYPE_REGIONS" = "TYPE_REGIONS",
  "TYPE_COUNTRIES" = "TYPE_COUNTRIES",
  "TYPE_MEDIA" = "TYPE_MEDIA",
  "TYPE_GENDER" = "TYPE_GENDER",
  "TYPE_STUDY" = "TYPE_STUDY",
}

export enum ROLE_PERMISSION_TYPES_TRANS {
  "TYPE_USERS" = "Socios",
  "TYPE_PRODUCTS" = "Socios - Productos",
  "TYPE_USERS_BILLING_ADDRESSES" = "Socios - Dir. Facturación",
  "TYPE_USERS_PAYMENT_METHODS" = "Socios - Mét. de pago",
  "TYPE_ORDERS" = "Pedidos",
  "TYPE_TICKETS" = "Tickets",
  "TYPE_CLAIMS" = "Reclamaciones",
  "TYPE_ADMINS" = "Administradores",
  "TYPE_ROLES" = "Roles",
  "TYPE_PLANS" = "Planes",
  "TYPE_JUDICIAL_PRECEDENT" = "Sentencias",
  "TYPE_JUDICIAL_PRECEDENT_INSTANCE" = "Sentencias - Instancias",
  "TYPE_JUDICIAL_PRECEDENT_PRODUCTS" = "Sentencias - Productos",
  "TYPE_JUDICIAL_PRECEDENT_AFFECTED_TYPE" = "Sentencias - Tipos",
  "TYPE_JUDGES" = "Jueces",
  "TYPE_LAWYERS" = "Abogados",
  "TYPE_BANKS" = "Bancos",
  "TYPE_AUDIENCES" = "Audiencias",
  "TYPE_COURTS" = "Juzgados",
  "TYPE_COURTS_TYPE" = "Juzgados - Tipos",
  "TYPE_FAQS" = "FAQS",
  "TYPE_PUBLIC_FILES" = "Doc. Públicos",
  "TYPE_PUBLIC_FILES_TYPE" = "Doc. Públicos - Tipos",
  "TYPE_PUBLIC_FILES_PRODUCTS" = "Doc. Públicos - Productos",
  "TYPE_NEWS" = "Novedades",
  "TYPE_ANALYTICS" = "Analíticas",
  "TYPE_ANALYTICS_PLANS" = "Analíticas - Planes",
  "TYPE_ANALYTICS_JUDICIAL_SENTENCES" = "Analíticas - Sentencias",
  "TYPE_ANALYTICS_FILES" = "Analíticas - Archivos",
  "TYPE_FINANCIAL_STRIPE" = "Financiero",
  "TYPE_HEADQUARTES" = "Sedes",
  "TYPE_CONFIGS" = "Configuraciones",
  "TYPE_CITIES" = "Ciudades",
  "TYPE_PROVINCES" = "Provincias",
  "TYPE_REGIONS" = "Regiones",
  "TYPE_COUNTRIES" = "Países",
  "TYPE_MEDIA" = "Medios",
  "TYPE_GENDER" = "Género",
  "TYPE_STUDY" = "Estudio",
}

export enum ROLE_PERMISSION_GRANTS_AVAILABLE {
  "GRANT_READ" = "GRANT_READ",
  "GRANT_WRITE" = "GRANT_WRITE",
  "GRANT_EDIT" = "GRANT_EDIT",
  "GRANT_DELETE" = "GRANT_DELETE",
  "GRANT_IMPORT" = "GRANT_IMPORT",
  "GRANT_DOWNLOAD" = "GRANT_DOWNLOAD",
}

export enum ROLE_PERMISSION_GRANTS_TRANS {
  "GRANT_READ" = "Ver",
  "GRANT_WRITE" = "Crear",
  "GRANT_EDIT" = "Editar",
  "GRANT_DELETE" = "Borrar",
  "GRANT_IMPORT" = "Importar",
  "GRANT_DOWNLOAD" = "Descargar",
}
